/**
 * Main clientscript class for abi-shirts.stickerei-stoiber.de.
 *
 * @author Stickerei Stoiber <info@stickerei-stoiber.de>
 * @copyright Stickerei Stoiber <info@stickerei-stoiber.de>
 */
/**
 * Main application object.
 *
 * @type Object
 */
Abi = {
    /**
     * Buckle your seatbelt Dorothy, 'cause Kansas is going bye-bye!
     */
    init: function () {
        this.MainMenu.init(); // Fixing main menu look and feel.

        // If slideshow container is present Slideshow is initiated.
        if ($('.header .slideshow').length) {
            this.Slideshow.init();
        }

        // If the #cat_selector_box is present it needs to be initiated.
        if ($('#cat_selector_box').length) {
            this.CatSelector.init();
        }

        // Running IE? Oh joy...
        if ($.browser.msie) {
            Abi.IE.init();
        }

        // Data privacy and GDPR related stuff.
        if ($('.data-protection-policy-banner ')) {
            Abi.DataProtection.init();
        }

        // Cookie consent system.
        CookieConsent._init();
    }
};

/**
 * Responsible for dealing with Uniform initialization.
 *
 * @type Object
 */
Abi.Uniform = {
    init: function () {
        // Firing up the Uniform site-wide transformation.
        $('select, input, textarea').not(function () {
            if ($(this).closest('#products-form').length || $(this).closest('#form-sidebar').length || $(this).closest('.cookie-consent-settings-option').length) { // Skipping some of the containers.
                return true;
            }
            return false;
        }).uniform({
            autoWidth: false,
            useID: false
        });
    }
};

/**
 * Deals with the "looks and feels" of the main navigation menu.
 *
 * @type Object
 */
Abi.MainMenu = {
    /**
     * Fires up the main menu.
     */
    init: function () {
        this.fixWidth(); // Fixing main menu subelements width.
    },
    /**
     * Fixes the inner elements width to fit the menu width (prevents empty space on teh right side).
     */
    fixWidth: function () {
        /**
         * Actual width of the menu elements (sum of all elements).
         *
         * @type Number
         */
        var actual_width = 0;
        /**
         * Width of the main menu wrapper.
         *
         * @type Number
         */
        var menu_width = 0;

        menu_width = $('.main_menu').width();

        // Calculating total width of main menu elements.
        $('.main_menu > li > a').each(function () {
            actual_width += $(this).outerWidth();
        });
        // Filling the remaining space by adjusting the last elements padding.
        $('.main_menu > li:last > a')
            .css('padding-left', "+=" + (Math.floor((menu_width - actual_width) / 2)) + "px")
            .css('padding-right', "+=" + ((menu_width - actual_width) - Math.floor((menu_width - actual_width) / 2)) + "px")
    }
};

/**
 * Responsible for dealing with the homepage carousel.
 *
 * @type Object
 */
Abi.Carousel = {
    /**
     * Duration of the carousel switch transition.
     *
     * @type Number
     */
    TRANSITION_TIMEOUT: 200,

    /**
     * Duration of the carousel slide animation.
     *
     * @type Number
     */
    SLIDE_DURATION: 247,

    // Carousel init stuff.
    init: function () {
        $('.carousel_wrapper').find('.fertigmotive_btn, .produkte_btn').bind('click', Abi.Carousel.switchTab); // Adding event handlers to carousel tabs.
        // Firing up Fertigmotive carousel.
        $('.fertigmotive .carousel').tinycarousel({
            duration: Abi.Carousel.SLIDE_DURATION
        });
        // Firing up Produkte carousel.
        $('.produkte .carousel').tinycarousel({
            duration: Abi.Carousel.SLIDE_DURATION
        });
    },

    /**
     * Switches carousel tabs.
     */
    switchTab: function () {
        /**
         * Class of the currently displayed carousel tab.
         *
         * @type jQuery
         */
        var currentTab = $(this);

        // Displaying (animating) the seleted carousel only if its not already displayed (its button is clickable).
        if (!currentTab.hasClass('selected')) {
            // Hiding both carousel containers and displaying the chosen one.
            $('.carousel_wrapper').children('div').each(function () {
                $(this).hide();
            });
            $('.carousel_wrapper .' + currentTab.attr('class').replace('_btn', '')).fadeIn(Abi.Carousel.TRANSITION_TIMEOUT);

            // Marking the new selected tab.
            $('.carousel_wrapper .switch a').each(function () {
                if ($(this).is(currentTab)) {
                    $(this).addClass('selected');
                } else {
                    $(this).removeClass('selected');
                }
            });
        }
    }
};

/**
 * Handles everything around product showcase functionality.
 */
Abi.ShowCase = {
    /**
     * Currently selected type (Eigenes Motiv / Fertiges Motiv).
     */
    currentType: 'eigenes_motiv',

    /**
     * ShowCase product name.
     */
    productName: '',

    /**
     * List of CSS classes which should get Colorbox event handlers attached.
     */
    colorBoxClasses: ['ColorboxDamen', 'ColorboxHerren'],

    /**
     * Getting the ShowCase ready.
     *
     * @param productName Product name supplied during instantiation.
     */
    init: function (productName) {
        this.productName = productName;

        // Attaching Colorbox event handlers to the small ShowCase picuters.
        $.each(this.colorBoxClasses, function (index, className) {
            $(".models a[class~=" + className + "]").colorbox({
                current: 'Bild {current} von {total}',
                previous: 'Zürick',
                next: 'Weiter',
                close: 'Schließen',
                rel: className,
                title: ''
            });
        });

        $('.side_info input[name="type"]').bind('click', this.switchType); // Eigenes Motiv / Fertiges Motiv type switch radio buttons.
        $('.sideinfo .fertiges_motiv').hide(); // Hiding Fertiges Motiv content.

        // Attaching event handlers to color selectors.
        $('.colors a').bind('click', this.changeColor);

        // Attaching Colorbox to the video controls.
        $('.models .video').colorbox({
            innerWidth: '860px',
            innerHeight: '515px',
            iframe: true,
            title: 'Stickerei Stoiber ' + this.productName
        });

        // Attaching event handlers to the zoom controls.
        $('.models .zoom').bind('click', this.zoomModel);

        // Attaching ColorBox event handlers for Price iframes.
        $('.side_info .prices').colorbox({
            innerWidth: '840px', //764px
            innerHeight: '542px', //542px
            iframe: true
        });

        // Attaching ColorBox event handlers for Size iframes.
        $('.side_info .sizes').colorbox({
            innerWidth: '892px', //892px
            innerHeight: '462px', //462px
            iframe: true
        });
    },

    /**
     * Toggles type (Eigenes Motiv / Fertiges Motiv) info display in the .side_info bar.
     */
    switchType: function () {
        if ($(this).val() != Abi.ShowCase.currentType) {
            $('.side_info .eigenes_motiv, .side_info .fertiges_motiv').hide();
            $('.side_info .' + $(this).val()).fadeIn('fast');
            Abi.ShowCase.currentType = $(this).val();
        }
    },

    /**
     * Changes the currenlty displayed color to the one being selected.
     */
    changeColor: function (e) {
        $('.models ul:first-child li').hide();
        $('.' + $(this).attr('class')).fadeIn('fast');
        $('.color_name').html($(this).attr('title'));
        Abi.ShowCase.currentColor = $(this).attr('class');
    },

    /**
     * Zooms in the currentlly displayed model.
     */
    zoomModel: function () {
        if ($(this).closest('ul').attr('class') == 'left_controls') {
            $('.models li:visible a.left').colorbox({
                open: true
            });
        } else {
            $('.models li:visible a.right').colorbox({
                open: true
            });
        }
    }
};

/*
* Controls price type display in Colorbox price table pages (Preistabelle).
*/
Abi.PriceSwitcher = {
    /**
     * Currently selected type (Eigenes Motiv / Fertiges Motiv).
     */
    currentType: 'eigenes_motiv',

    init: function () {
        $('.cbox_price_switcher input').uniform();
        $('.cbox_price_switcher input').bind('click', this.switchType);
    },

    /**
     * Displays a different set of prices inside the colorbox (Eigenes Motiv / Fertiges Motiv).
     */
    switchType: function () {
        if ($(this).val() != Abi.PriceSwitcher.currentType) {
            $('.cbox_prices .eigenes_motiv, .cbox_prices .fertiges_motiv').hide();
            $('.cbox_prices .' + $(this).val()).fadeIn('fast');
            Abi.PriceSwitcher.currentType = $(this).val();
        }
    }
};

/**
 * Handles the openning / closing animation for the contact sidebar.
 */
Abi.ContactSidebar = {
    /**
     * Duration of the open / close animation in milliseconds.
     */
    ANIMATION_DURATION: 'fast',
    /**
     * Determins on which side of the page is the sidebar placed.
     *
     * @type String
     */
    side: '',

    init: function () {
        // Determining on which side of the page is the sidebar located.
        if ($('.sidebar_contact').hasClass('right')) {
            this.side = 'right';
        } else {
            this.side = 'left';
        }
        // Adding close / open event handlers for contact sidebar open / close switch.
        $('.sidebar_contact .sidebar_switch').bind('click', this.toggle);
    },

    /**
     * Opens / closes the contact sidebar with an animation.
     */
    toggle: function () {
        var params = {};
        if (parseInt($('.sidebar_contact').css(Abi.ContactSidebar.side)) < 0) { // Left position negative (indicating closed sidebar)?
            params[Abi.ContactSidebar.side] = 0;
        } else {
            params[Abi.ContactSidebar.side] = -($(this).siblings('div.sidebar_data').width() + 1)
        }
        $('.sidebar_contact').animate(params, this.ANIMATION_DURATION);
    }
};

/**
 * Stuff that will be executed after the rest of the page is loaded. Prevents page blocking.
 *
 * @type Object
 */
Abi.PostLoad = {
    init: function () {
        Abi.Uniform.init(); // Firing up Uniform.

        $('document').ready(function() {
            if (CookieConsent.config.statistics) {
                Abi.Social.init(); //Hello Facebook... Hello Twitter...
            } else {
                $('.social .share').hide();
            }
        });
    }
};

/**
 * Handles the FAQ page.
 */
Abi.FAQ = {
    // Initialization stuff.
    init: function () {
        // Fixes the nasty slide jumping issue by explicitelly assigning heights and then hides all the answers.
        $('.faq p').each(function () {
            $(this).css('height', $(this).height()).hide();
        });

        $('.faq a').bind('click', this.toggle); // Adding event handlers to display / hide the answers.
    },

    /**
     * Displays / hides the FAQ answer for the clicked element.
     */
    toggle: function () {
        var question = $(this);
        var answer = $(this).siblings('p');

        if ($('.faq p:visible').length) {
            $('.faq p:visible').slideUp(function () {
                $(this).siblings('a').find('.toggle').removeClass('openned');
            });
        }

        if (answer.is(':hidden')) {
            answer.slideDown(function () {
                question.find('.toggle').addClass('openned');
            })
        }
    }
};

/**
 * Handles Fertigmotive and Namenslisten pagers.
 */
Abi.Pager = {
    /**
     * Duration of the page scrolling animation which occurs if a hash ID is provided.
     *
     * @type Number
     */
    SCROLL_DURATION: 1000,

    /**
     * Duration of the content slide animation.
     *
     * @type Number
     */
    SLIDE_DURATION: 200,

    /**
     * Does some initial stuff for the Pager.
     */
    init: function () {
        /**
         * Either an object referrence to the first page or string representing its name.
         *
         * @type Mixed
         */
        var initialPage;

        // Displaying only one of the pager contents, hidding all the rest.
        if (window.location.hash.length && $('.pager_content').find(window.location.hash).length) {
            // If there is a hash supplied the respective pager container containing it should be displayed.
            initialPage = $('.pager_content').find(window.location.hash).closest('.pager_content');
        } else {
            // Nope... No hash supplied... Displaying the first page, hiding all the rest.
            initialPage = ':first';
        }
        // Displaying initial page and scrolling the page to an ID if provided by hash.
        $('.pager_content').not(initialPage).hide(function () {
            if ($('.pager_content').find(window.location.hash).length) {
                $('html, body').animate({scrollTop: $('.pager_content ' + window.location.hash).offset().top}, 1000);
            }
        });

        //Setting up event handlers for page links.
        $('.pager_links a').bind('click', Abi.Pager.switchToPage);

        // Assigning Colorbox to Pager images.
        $('.pager_content a').colorbox({
            current: "Bild {current} von {total}",
            previous: "Z\u00fcrick",
            next: "Weiter",
            close: "Schlie\u00dfen",
            rel: "gallery"
        });

        // Should not actually go in here but since these links are only available on Pager pages well..
        $('.lower_info .price_table').colorbox({
            iframe: true,
            innerWidth: '520px',
            innerHeight: '575px',
            scrolling: false
        });
        // This one too...
        $('.lower_info .price_example').colorbox({
            iframe: true,
            innerWidth: '800px',
            innerHeight: '675px',
            scrolling: false
        });
    },

    /**
     * Switches the Pager to the selected page.
     *
     */
    switchToPage: function (e) {
        /**
         * Index position of the clicked anchor in the pager_links section.
         *
         * @type Number
         */
        var linkIndex = $(this).closest('.pager_links').find('a').index(this);
        /*
        * Position of the side scroll slider before the switching animation.
        *
        * @type Number
        */
        var topX = $('body').scrollTop() | $('html').scrollTop(); // Taking values from either IE or the rest of the browsers.

        // Content switching is done only if the target pager content section is hidden.
        if ($('.pager_content').eq(linkIndex).is(':hidden')) {
            $('.pager_content:visible').fadeOut(Abi.Pager.SLIDE_DURATION, function () {
                $('.pager_content').eq(linkIndex).fadeIn(Abi.Pager.SLIDE_DURATION);
                $('html, body').scrollTop(topX); // Must execute after the above line to prevent a scroll problem with Chrome.
            });
        }

    }
};

/**
 * Header slideshow subobject.
 */
Abi.Slideshow = {
    /**
     * Delay between header animations in milliseconds.
     */
    ANIMATION_DELAY: 5000,

    /**
     * How long should the animation last.
     */
    ANIMATION_DURATION: 500,

    /***
     * Instance of the header animation interval.
     */
    slideInterval: undefined,

    /**
     * Position of the currently displayed image.
     */
    currentSlide: 1,

    /**
     * Slideshow images picked from the slideshow container.
     */
    images: $('<div>'),

    /**
     * Initiates the header slideshow subobject,
     */
    init: function () {
        Abi.Slideshow.shuffle(); // Randomizing image display while images are still not loaded..

        // Slideshow needs to be initiated only after the images have been loaded otherwise overlaps will be visible.
        $(window).load(function () {
            // All images need to get a z-index assigned...
            $('.slideshow img').each(function (index) {
                $(this).css({
                    'z-index': $('.slideshow img').length - index,
                    'position': 'absolute',
                    'right': 0,
                    'top': 0
                });

                //... and hidden (except for the first image).
                if (index) {
                    $(this).hide();
                }
            });

            Abi.Slideshow.start(); // Starting the slideshow.
        })
    },

    /**
     * Starts the header animation.
     */
    start: function () {
        this.slideInterval = setInterval(this.nextSlide, this.ANIMATION_DELAY);
    },

    /**
     * Stopps the header animation.
     *
     * @return {Boolean} Animation stopped successfully?
     */
    stop: function () {
        if (this.slideInterval) {
            clearInterval(this.slideInterval);
            return true;
        }
        return false;
    },

    /**
     * Displays the next in row slide image.
     **/
    nextSlide: function () {
        $('.slideshow').find('img:visible').fadeOut(Abi.ShowCase.ANIMATION_DURATION);

        if (Abi.Slideshow.currentSlide == $('.slideshow img').length) {
            Abi.Slideshow.currentSlide = 1;
        } else {
            Abi.Slideshow.currentSlide++;
        }

        $('.slideshow').find('img:eq(' + parseInt(Abi.Slideshow.currentSlide - 1) + ')').fadeIn(Abi.ShowCase.ANIMATION_DURATION);
    },

    /**
     * Randomizes the display order of header slideshow images
     */
    shuffle: function () {
        // Moving all of the slideshow images inside a blank jQuery object and leaving a marker (span container)
        $('.slideshow').children().each(function () { // First image shoould be skipped since its always the company image.
            Abi.Slideshow.images.append($(this).clone());
            $(this).replaceWith('<span></span>');
        });

        // Every marker in the slideshow is now replaced with a randomly choosen image from the images which are left in the current switch cycle.
        do {
            $('.slideshow span:first').replaceWith(this.images.children().eq(Math.floor(Math.random() * this.images.children().length)));
        } while ($('.slideshow span:first').length);
    }
};

/**
 * Quick category switcher for the Blog section.
 */
Abi.CatSelector = {
    /**
     * String representing the last part of the Blog pathname.
     *
     * @type String
     */
    CAT_URL_REDIRECT_BASE: 'blog/',
    /**
     * Absolute path to the Blog root URL.
     *
     * @type String
     */
    wl: '',

    init: function () {
        /**
         * Absolute path of the URL pointing to the Blog root.
         */
        var index;
        // Attaching an event handler to the select box.
        $('#cat_selector_box').bind('change', Abi.CatSelector.switchCategory);

        // Determining the absolute path of the URL pointing to the Blog root.
        if ((index = location.href.indexOf(Abi.CatSelector.CAT_URL_REDIRECT_BASE)) != -1) {
            this.wl = location.href.substr(0, index + Abi.CatSelector.CAT_URL_REDIRECT_BASE.length);
        } else {
            this.wl = location.protocol + '//' + location.hostname + (location.port ? (':' + location.port + '/') : '/') + Abi.CatSelector.CAT_URL_REDIRECT_BASE;
        }
    },
    /**
     * Redirects the browser to the corresponding page.
     */
    switchCategory: function () {
        if ($(this).val() != 0) {
            window.location.href = Abi.CatSelector.wl + $(this).val();
        } else {
            window.location.href = Abi.CatSelector.wl;
        }
    }
};

/**
 * Deals with social networks such as Facebook, Twitter and Google +.
 */
Abi.Social = {
    init: function () {
        $('.facebook').html('<fb:like send="false" layout="button_count" width="110" show_faces="false" font=""></fb:like>');
        $('.twitter').html('<a href="http://twitter.com/share" class="twitter-share-button" data-count="none" data-lang="de">Twittern</a>');
        // In case of any other page except the homepage the social buttons from the view are removed.
        FB.init({
            appId: '143126085766138',
            status: true,
            cookie: true,
            xfbml: true
        });
    }
};

/**
 * Responsible for manipulating embedded YouTube videos.
 */
Abi.Videos = {
    init: function () {
        // Attaching colorbox to videos.
        $(document).ready(function () {
            $('.yt_videos a').colorbox({
                innerWidth: '860px',
                innerHeight: '515px',
                iframe: true
            });
        });
    }
};

/**
 * Deals with data privacy banner and GDPR related stuff.
 */
Abi.DataProtection = {
    /**
     * Data protection policy banner cookie configuration.
     */
    dataProtectionPolicyCookie: {
        name: 'dataProtectionPolicyAccepted',
        expiration: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000).toUTCString(),
        path: '/'
    },

    /**
     * Google Analytics Opt-Out cookie configuration.
     */
    gaOptOutCookie: {
        name: 'ga-disable-UA-4060526-2',
        expiration: 'Thu, 31 Dec 2099 23:59:59 UTC',
        path: '/'
    },

    init: function () {
        $(window).on('load', function () {
            // There is no data protection policy cookie present (data privacy not accepted).
            if (document.cookie.indexOf(Abi.DataProtection.dataProtectionPolicyCookie.name + '=true') === -1) {
                // Event that triggers when the data privacy banner button is clicked.
                $('.data-protection-policy-accept-button').on('click', Abi.DataProtection.dataProtectionPolicyAccepted);
                Abi.DataProtection.toggleBanner();
            }

            // Current user has not chosen to opt out of Google Analytics.
            if (document.cookie.indexOf(Abi.DataProtection.gaOptOutCookie.name + '=true') === -1) {
                $('.google-analytics-opt-out-button').on('click', Abi.DataProtection.googleAnalyticsDisabled);
            } else {
                $('.google-analytics-opt-out-button').prop('disabled', 'disabled');
            }
        });
    },

    /**
     * Event handler that triggers when the data privacy banner button is clicked.
     */
    dataProtectionPolicyAccepted: function () {
        Abi.DataProtection.toggleBanner();

        document.cookie = Abi.DataProtection.dataProtectionPolicyCookie.name + '=true; expires=' +
            Abi.DataProtection.dataProtectionPolicyCookie.expiration + '; path=' +
            Abi.DataProtection.dataProtectionPolicyCookie.path;
    },

    /**
     * Toggles the data privacy banner.
     */
    toggleBanner: function () {
        var banner = $('.data-protection-policy-banner'),
            visible = banner.attr('data-visible');
        // visible can be "undefined" -> Boolean(undefined) === false
        if (!visible || visible === 'false') {
            banner.attr('data-visible', 'true');

            return;
        }

        banner.attr('data-visible', 'false');
    },

    /**
     * Event handler which fires after the Google Analytics disable button is clicked.
     */
    googleAnalyticsDisabled: function () {
        document.cookie = Abi.DataProtection.gaOptOutCookie.name + '=true; expires=' +
            Abi.DataProtection.gaOptOutCookie.expiration + '; path=' +
            Abi.DataProtection.gaOptOutCookie.path;

        $('.google-analytics-opt-out-button').prop('disabled', 'disabled');

        // Not that it has any impact after GA is initiated but let's honor the form.
        window[Abi.DataProtection.gaOptOutCookie.name] = true;
    }
};

/**
 * Functionality related to the cookie consent system.
 */
CookieConsent = {
    /**
     * The consent dialog slide up animation delay in ms.
     */
    DIALOG_DISPLAY_TIMEOUT: 500,

    /**
     * Duration of cookie consent in days.
     * The number defaults to 26 months and is taken from the privacy policy statement.
     */
    DEFAULT_COOKIE_CONSENT_DURATION: 780,

    /**
     * Name of the cookie that will store the cookie consent configuration.
     */
    COOKIE_CONSENT_NAME: 'cookie_consent_config',

    /**
     * Default values for the cookie consent preferences.
     * NOTICE: required must be set to true at all times!
     */
    config: {
        required: true,
        statistics: false,
        preferences: false,
        marketing: false
    },

    _init: function () {
        console.log('[CookieConsent] Initializing...');

        // Settings event handlers.
        $('#cookie-consent-dialog-accept-btn').click(CookieConsent._click_DialogAcceptButton);
        $('#cookie-consent-dialog-settings-btn').click(CookieConsent._click_DialogConfigButton);
        $('#cookie-consent-settings-close-btn').click(CookieConsent._click_SettingsCloseButton);
        $('#cookie-consent-settings-save-btn').click(CookieConsent._click_SettingsSaveButton);
        $('#cookie-consent-settings-exit-btn').click(CookieConsent._click_SettingsExitButton);
        $('#cookie-consent-floater-show-btn').click(CookieConsent._click_FloaterShowButton);
        $('#cookie-consent-floater-delete-btn').click(CookieConsent._click_FloaterDeleteButton);

        // The following even handler is specific for the data protection privacy page as it triggers the cookie settings button there.
        $('#data-protection-policy-cookie-settings-btn').click(CookieConsent._click_DataProtectionPolicySettingsButton);

        // Check for existence of the cookie consent preferences cookie.
        if (!CookieConsent.Cookies.getCookie(CookieConsent.COOKIE_CONSENT_NAME)) {
            // The timeout makes sure there are no animation hiccups.
            setTimeout(function () {
                $('.cookie-consent-dialog').slideToggle('slow');
            }, CookieConsent.DIALOG_DISPLAY_TIMEOUT);
        } else {
            // Load existing config data.
            CookieConsent.loadConfig();

            // Update the dialog options.
            CookieConsent.updateDialogOptions();
        }
    },

    /**
     * Loads the cookie consent configuration from the cookies.
     */
    loadConfig: function () {
        // Out of precaution, return false if no cookie is yet set.
        if (!CookieConsent.Cookies.getCookie(CookieConsent.COOKIE_CONSENT_NAME)) {
            return false;
        }

        // Very unsafe but should do the trick.
        CookieConsent.config = JSON.parse(decodeURIComponent(CookieConsent.Cookies.getCookie(CookieConsent.COOKIE_CONSENT_NAME)));
    },

    /**
     * Stores the current cookie consent configuration into a cookie.
     */
    storeConfig: function () {
        CookieConsent.Cookies.setCookie(CookieConsent.COOKIE_CONSENT_NAME, encodeURIComponent(JSON.stringify(CookieConsent.config)), CookieConsent.DEFAULT_COOKIE_CONSENT_DURATION);
    },

    /**
     * Updates the dialog cookie consent options.
     */
    updateDialogOptions: function () {
        for (var index in CookieConsent.config) {
            $('.cookie-consent-settings-option input[type="checkbox"][data-consent-option-name="' + index + '"]').prop('checked', CookieConsent.config[index]);
        }
    },

    /**
     * Event handler: dialog accept button clicked.
     */
    _click_DialogAcceptButton: function () {
        // Setting all config values to true.
        for (var index in CookieConsent.config) {
            CookieConsent.config[index] = true;
        }

        // Updating the stored cookie configuration.
        CookieConsent.storeConfig();

        // Fading out the dialog.
        $('.cookie-consent-dialog').slideToggle();

        // Update the settings dialog.
        CookieConsent.updateDialogOptions();
    },

    /**
     * Event handler: dialog settings button clicked.
     */
    _click_DialogConfigButton: function () {
        $('.cookie-consent-settings').fadeIn('fast');
        $('.cookie-consent-dialog, .cookie-consent-settings-overlay').toggle();
    },

    /**
     * Event handler: settings close button clicked.
     */
    _click_SettingsCloseButton: function () {
        $('.cookie-consent-settings, .cookie-consent-settings-overlay').fadeOut('fast');
    },

    /**
     * Event handler: settings save button clicked.
     */
    _click_SettingsSaveButton: function () {
        var options = $('.cookie-consent-settings-option input[type="checkbox"]'),
            optionName = '';

        // Iterating through all the option checkboxes.
        $.each(options, function (index) {
            optionName = $(options[index]).data('consent-option-name');
            CookieConsent.config[optionName] = !!$(options[index]).prop('checked');
        });

        // Update the configuration in the cookie.
        CookieConsent.storeConfig();

        // Close the settings dialog and overlay.
        $('.cookie-consent-settings, .cookie-consent-settings-overlay').fadeOut('fast');
    },

    /**
     * Event handler: settings exit button clicked.
     */
    _click_SettingsExitButton: function () {
        $('.cookie-consent-settings, .cookie-consent-settings-overlay').fadeOut('fast');
    },

    /**
     * Event handler: floater show button clicked.
     */
    _click_FloaterShowButton: function () {
        $('.cookie-consent-settings, .cookie-consent-settings-overlay').fadeIn('fast');
    },

    /**
     * Event handler: floater button clicked.
     */
    _click_FloaterDeleteButton: function () {
        // Reset all the cookie consent settings by deleting the cookie entirely.
        CookieConsent.Cookies.deleteCookie(CookieConsent.COOKIE_CONSENT_NAME);

        // Reload the page.
        window.location.reload(true);
    },

    /**
     * Event handler: cookie settings button on the data protection policy
     */
    _click_DataProtectionPolicySettingsButton() {
        // Displaying the settings window.
        $('.cookie-consent-settings').fadeIn('fast');
        $('.cookie-consent-settings-overlay').toggle();

        // If the lower dialog is visible at this point, hide it.
        if ($('.cookie-consent-dialog').is(':visible')) {
            $('.cookie-consent-dialog').toggle();
        }
    }
}

/**
 * I don't trust the original cookie handling code so this is a self-contained
 * implementation intended just for the cookie consent system.
 */
CookieConsent.Cookies = {
    /**
     * Retrieves a cookie value.
     *
     * @param name Name of the cookie.
     * @returns {string|boolean}
     */
    getCookie: function (name) {
        if (!document.cookie.length || document.cookie.indexOf(name) === -1) {
            return false;
        }

        // Space in .split('; ') is required due to how browsers store additional keys.
        let cookies = document.cookie.split('; ');

        for (let cookie in cookies) {
            if (cookies.hasOwnProperty(cookie) && cookies[cookie].indexOf(name) !== -1) {
                return cookies[cookie].split('=')[1];
            }
        }
    },

    /**
     * Sets a cookie.
     *
     * @param name Name of the cookie to set.
     * @param value Value of the cookie to set.
     * @param days Number of days before the cookie expires.
     */
    setCookie: function (name, value, days) {
        let expires = '';
        if (days) {
            expires = '; expires=' + new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000).toUTCString();
        }

        document.cookie = name + '=' + value + expires + '; path=/';
    },


    /**
     * Deletes a cookie.
     *
     * @param name Name of the cookie to delete.
     */
    deleteCookie: function (name) {
        if (document.cookie.indexOf(name) === -1) {
            return false;
        }

        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
}
